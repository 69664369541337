// @ts-strict-ignore
import colors from '../colors'
import { SessionRoleType } from '../model/Common/common.interface'
import { getEnv } from './envUtil'
import { COOKIE_DOMAIN, IS_LOCAL } from './envVars'

// KEYS

//https://developers.kakao.com/docs/latest/ko/getting-started/sdk-js#init
export const KAKAO_JAVASCRIPT_KEY = '55483657416ae66c9098fea54401c645'
export const KAKAO_REST_API_KEY = '6a31196dfbca6e1c5653e7c841ebb162'

export const PUSHER_APP_KEY = '6f4e2acaf256ab429686'

export const NAVER_KEY_TEENS = 'q9f3d630byefcSEtIfYc'

// URLS
export const CDN_URL = 'https://d38emex6h5e12i.cloudfront.net'

// 자동 매칭
export const AUTO_MATHCING_ID = 'auto-matching'
export const IMAGE_AUTO_MATCHING = '/assets/img/logo/logo1.png'

// 유저 역할
export const ROLE_STUDENT = 0
export const ROLE_TUTOR = 1
export const ROLE_ADMIN = 3

// 결제 타입
export const IAMPORT_NOAUTH = 0
export const IAMPORT_INTL = 1

export const IAMPORT_AUTH = 4
export const PAYPAL_EXPRESS = 5
export const STRIPE = 6
export const RECURRING = 7

export const TOSS_AUTH = 9
export const VBANK = 10

// Iamport 결제
export const IAMPORT_KEY = 'imp96397147'

// Stripe 결제
// export const STRIPE_PK = getEnv('CONF_STRIPE_PK', undefined)

// 긴급 편성/보충 수업 타입
export const APPLY_LESSON_LIMIT_TIME = 48

export const DOLLARS_TO_KRW = 1300

// Hackle SDK Key
export const HACKLE_SDK_KEY = getEnv('CONF_HACKLE_SDK_KEY', undefined)

// 쿠키
export const COOKIE_OPT = { domain: COOKIE_DOMAIN, path: '/' }
export const COOKIE_OPT_FOR_ACCESS_TOKEN = { domain: COOKIE_DOMAIN, path: '/', secure: !IS_LOCAL }
export const COOKIE_LIST = {
  LOGGED_BY_PARENT: 'logged_by_parent',
  JWT_TOKEN: 'jwt_token',
  LOGIN_AFTER_URL: 'login_after_url',
  IS_ADMIN_LOGIN: 'is_admin_login',
  REFERRAL_CODE: 'referralCode',
  ROOT_URL: 'root_url',
  PHONE: 'phone',
  PHONE_INTL: 'phone_intl',
  EMAIL: 'email',
  UTM_AD_INFO: 'utm_ad_info',
  PROVIDER: 'provider',
  UID: 'uid',
  USER_TYPE: 'user_type',
  USER_IMAGE: 'userImage',
  TIMEZONE: 'timezone',
  USER_ID: 'user_id',
  NAME: 'name',
  ACCOUNT_TYPE: 'user_type',
  ACCOUNT_ID: 'account_id',
  TEMP_RINGLEDOCTOKEN: 'temp_ringleDocToken',
  TEMP_ROLE: 'temp_role',
  TEMP_USERNAME: 'temp_username',
  LOCALE: 'locale',
  BSF_FLOATING_POPUP: 'bsf-floating-popup',
  IS_CLOSE_COOKIE_BANNER: 'is_close_cookie_banner',
  CHECK_COUPON: 'check_coupon',
  IS_CLOSE_EARLY_PROMOTION_BANNER: 'is_close_early_promotion_banner',
  IS_CLOSE_ZOOM_NOTICE: 'is_close_zoom_notice',
  MATERIAL_TO_SCHEDULE: 'material_to_schedule',
  UNASSIGNED_LESSONS_TOOLTIP: 'unassigned_lessons_tooltip',
  SESSION_ROLE: 'session_role',
  REDIRECT_URL: 'redirect_url',
  PARENT_TOKEN: 'parent_token',
  CLOSED_POPUP: (popupId) => `is_closed_popup_${popupId}`,
  CLOSED_GNB: (gnbId) => `is_closed_gnb_${gnbId}`,
  PROMOTION_POPUP: 'promotion_popup_2024_1',
  PROMOTION_EVENT_POPUP: 'promotion_popup_event_2024_1',
  PROMOTION_INFO_POPUP: 'promotion_popup_info_2023_0616',
  SPEECH_POPUP: 'speech_popup_2023_05',
  IS_CLOSE_PROMOTION_BANNER: 'is_close_promotion_banner',
  IS_CLOSE_SPEECH_CONTEST_BANNER: 'is_close_speech_contest_banner',
  IS_CLOSE_CAF_MODAL_IN_REVIEW: 'is_close_caf_modal_in_review',
}

// S3 버켓
export const RECORDING_VIDEO_ASIA_BUCKET = 'recording-video-asia'

// 취소시 옵션
export const MATCHING_AFTER = {
  AUTOMATIC_NOT_TUTOR_SELECTED: 0,
  AUTOMATIC_MATCHING_ME: 1,
  SHOW_AVAILABLE_TIMES: 2,
  CANCEL_RESTORE_CREDIT: 3,
}

// 튜터 상태
export const TUTOR_STATUS = {
  APPLICATION: 0,
  ACTIVE: 1,
  NEW_TUTOR: 2,
  DORMANT: 3,
  READY: 4,
  ONE_STRIKE: 5,
  TWO_STRIKE: 6,
  OUT_THREE_STRIKE: 7,
  OUT_TEN_STRIKE: 8,
  HOLD: 9,
  BLACKLIST: 10,
  NOT_AGREED_POLICY: 11,
  UNDER_REVIEW: 12,
}

export const TUTOR_SCHEDULE_OPEN_STATUS = {
  AVAILABLE: 1,
  FULL_BOOKED: 2,
  NOT_OPEN_YET: 3,
}

// 분할 결제 타입
export const PARTIAL_STATUS = {
  BEFORE: 0,
  START: 1,
  FINISH: 2,
}

export const SIGN_UP_MODE = {
  TUTOR: 'tutor',
  TOPIC: 'topic',
  TRIAL: 'trial',
  EVENT: 'event',
  WEBINAR: 'webinar',
  CORRECTION: 'correction',
  PURCHASE: 'purchase',
  MATERIAL_AD_SIGN_UP: 'material_ad_sign_up',
  MATERIAL_AD_SIGN_IN: 'material_ad_sign_in',
  SIGN_IN: 'sign_in',
}

export const TEENS_SESSION_ROLE: { [key: string]: SessionRoleType } = {
  CHILD: 'child',
  PARENT: 'parent',
}

export const TEENS_ACCOUNT_TYPE = {
  TUTOR: 'Tutor',
  PLUS: 'Plus',
  PARENT: 'Parent',
  CHILD: 'Child',
  NEW: 'New',
  PLUS_PARENT: 'Plus_Parent',
  STUDENT: 'Student',
}
export const PAYLOAD_SESSION_ROLE = {
  STUDENT: 'student',
  PARENT: 'parent',
}
export const ONBOARDING_STAGE = {
  PARENT: {
    SIGNUP_FINISHED: 1,
    CHILD_ACCOUNT_CREATED: 2,
  },
  CHILD: {
    ONBOARDING_NOT_FINISHED: 0,
    ONBOARDING_FINISHED: 1,
  },
}
export const ROLE = {
  TUTOR: 1,
  STUDENT: 0,
  ADMIN: 3,
}

export const WEBINAR_CATEGORY = {
  GRAMMAR: 1,
  RINGLE_CLASS: 2,
  TREND: 3,
  RADIO: 4,
  BUSINESS: 5,
}

export const CONFIRM_STATUS = {
  CONFIRMED: 1,
  PENDING: 2,
  CANCELED: 3,
}

export const PRE_STUDY = {
  INSIGHT: 'insight',
  YOUTUBE: 'youtube',
  ARTICLE: 'article',
  MP3: 'mp3',
  EXPRESSION: 'expression',
  QUESTION: 'question',
  UPLOAD: 'upload',
  UPLOAD_FILE: 'upload_file',
  STYLE: 'style',
}

export const STUDY_REVIEW = {
  MP3: 'mp3',
  SCRIPT: 'script',
  FEEDBACK: 'feedback',
  HOMEWORK: 'homework',
}

export const USER_INFO_STATUS = {
  START: 1,
  COMPLETED: 2,
}

export const MAX_RATING = 5

// Sentry

export const NEXT_PUBLIC_SENTRY_DSN_TEENS = 'https://017931569f084e1db0be523569361d50@o199905.ingest.sentry.io/6497135'

// Free topic 교재
export const SPEAKING_FOCUSED_FOR_INTERVIEW = 1481

export const FACEBOOK_APP_ID = '1070991856251904'
export const GOOGLE_CLIENT_ID = '398556539785-0a99kq821p2kdop2h5pkvbpho9tqnli2.apps.googleusercontent.com'

// APP link
export const RINGLE_DYNAMIC_LINK = 'https://goo.gl/QfALoK'

// 카카오톡 채널 링크
export const RINGLE_TEENS_KAKAO_CHAT = 'https://pf.kakao.com/_SKVxexj/chat'

// Toss

export const TOSS_CLIENT_KEY = 'live_ck_YyZqmkKeP8goBML19q48bQRxB9lG'

// -------------------------------
// 이미지 url은 images.ts에 추가해주세요!
// -------------------------------
export const BRAZE_TEENS_WEB_KEY_PROD = '34ffe221-2d33-4c44-a971-4225f4e3a2d2'

// Track
export const LOG_CATEGORY = {
  READ: 0,
  WRITE: 1,
  LISTEN: 2,
  SPEAK: 3,
  VIEW: 4, //삭제 예정
}
export const LOG_TYPE = {
  COURSE: {
    READ: 0, //o
    MP3_LISTEN: 1, //o
    VIDEO: 2, //o
    ARTICLE: 3, //o
    EXPRESSION_STUDY: 4, //o
    EXPRESSION_LISTEN: 5, //o
    ANSWER_WRITE: 6, //o
    ANSWER_READ: 7, //링킹 X
    REQUESTED_TEXT_WRITE: 17,
  },
  LESSON: {
    NOTE_WRITE: 8, //링킹 X
    NOTE_READ: 9, //링킹 X
    LISTEN: 10, //o
    FEEDBACK_READ: 11, //o
    ENTER: 12, //o
  },
  WEBINAR: {
    VIEW: 13, //o
    LIVE_VIEW: 14, //o
  },
  CHALLENGE: {
    MEMO: 15, //링킹 X
    QUIZ: 16, //링킹 X
  },
  CURATION: {
    COURSE_READ: 18,
    WEBINAR_READ: 19,
  },
}
export const LOCATION = {
  PREP: 0,
  REVIEW: 1,
  COURSE: 2,
  LESSON: 3,
  WEBINAR: 4,
  WEBINAR_LIVE: 5,
  // CHALLENGE: 6,
  CURATION: 7,
  HOMEWORK: 8,
}

export const MY_ORIGINAL = {
  WEBINAR: 1,
  COURSE: 2,
  MY_TUTOR: 3,
  EXP_TUTOR: 4,
  EXP_COURSE: 5,
  MY_DAILY_BRIEF: 6,
}

export const ASIA_SEOUL = 'Asia/Seoul'

export const TUTOR_TYPE = {
  NEW_TUTOR: 'newTutors',
  ALL_TUTOR: 'allTutors',
  MY_TUTOR: 'myTutors',
  NEW_UNOCCUPIED_TUTOR: 'newUnoccupiedTutors',
  OLD_UNOCCUPIED_TUTOR: 'oldUnoccupiedTutors',
  EXPERIENCE_TUTOR: 'experienceTutors',
  SEARCH_TUTOR_RESULT: 'searchedTutors',
  RECOMMEND_TUTOR: 'recommendTutors',
}

export const MATERIAL_TYPE = {
  ALL: 'all',
  MY: 'my',
}

// COLORS
export const COLORS = {
  WHITE: '#ffffff',
  PRIMARY: '#4c9cff',
  PRIMARY_LIGHT: '#dbebff',
  SECONDARY: '#317cd9',
  SECONDARY2: '#105dbb',
  SUCCESS: '#3acabb',
  SUCCESS_LIGHT: '#d8f4f1',
  INFO: '#b774ec',
  INFO_LIGHT: '#f5e5ff',
  WARNING: '#f6d34a',
  DANGER: '#fa5c5c',
  DANGER_LIGHT: '#ffd2d2',
  GRAY100: '#f5f5f5',
  GRAY200: '#edeeee',
  GRAY300: '#dfe1e3',
  GRAY400: '#cacccf',
  GRAY500: '#979797',
  GRAY600: '#989aa2',
  GRAY700: '#5a5a5b',
  BLACK: '#1b1b1b',
  DIM: colors.dim,

  PURPLE: colors.purple.DEFAULT,
  PURPLE50: colors.purple[50],
  PURPLE100: colors.purple[100],
  PURPLE200: colors.purple[200],
  PURPLE300: colors.purple[300],
  PURPLE400: colors.purple[400],
  PURPLE500: colors.purple[500],
  PURPLE600: colors.purple[600],
  PURPLE700: colors.purple[700],
  PURPLE800: colors.purple[800],
  PURPLE900: colors.purple[900],

  BLUE: colors.blue.DEFAULT,
  BLUE50: colors.blue[50],
  BLUE100: colors.blue[100],
  BLUE200: colors.blue[200],
  BLUE300: colors.blue[300],
  BLUE400: colors.blue[400],
  BLUE500: colors.blue[500],
  BLUE600: colors.blue[600],
  BLUE700: colors.blue[700],
  BLUE800: colors.blue[800],
  BLUE900: colors.blue[900],

  GREEN: colors.green.DEFAULT,
  GREEN50: colors.green[50],
  GREEN100: colors.green[100],
  GREEN200: colors.green[200],
  GREEN300: colors.green[300],
  GREEN400: colors.green[400],
  GREEN500: colors.green[500],
  GREEN600: colors.green[600],
  GREEN700: colors.green[700],
  GREEN800: colors.green[800],
  GREEN900: colors.green[900],

  RED: colors.red.DEFAULT,
  RED50: colors.red[50],
  RED100: colors.red[100],
  RED200: colors.red[200],
  RED300: colors.red[300],
  RED400: colors.red[400],
  RED500: colors.red[500],
  RED600: colors.red[600],
  RED700: colors.red[700],
  RED800: colors.red[800],
  RED900: colors.red[900],

  YELLOW: colors.yellow.DEFAULT,
  YELLOW50: colors.yellow[50],
  YELLOW100: colors.yellow[100],
  YELLOW200: colors.yellow[200],
  YELLOW300: colors.yellow[300],
  YELLOW400: colors.yellow[400],
  YELLOW500: colors.yellow[500],
  YELLOW600: colors.yellow[600],
  YELLOW700: colors.yellow[700],
  YELLOW800: colors.yellow[800],
  YELLOW900: colors.yellow[900],
}

export const COUNTRY_CODE = [
  { name: 'South Korea', code: '+82' },
  { name: 'United States', code: '+1' },
  { name: 'Japan', code: '+81' },
  { name: 'Singapore', code: '+65' },
  { name: 'Canada', code: '+1' },
  { name: 'United Kingdom', code: '+44' },
  { name: 'Australia', code: '+61' },
  { name: 'Germany', code: '+49' },
  { name: 'Vietnam', code: '+84' },
  { name: 'Hong Kong', code: '+852' },
  { name: 'Netherlands', code: '+31' },
  { name: 'Afghanistan', code: '+93' },
  { name: 'Aland Islands', code: '+358' },
  { name: 'Albania', code: '+355' },
  { name: 'Algeria', code: '+213' },
  { name: 'AmericanSamoa', code: '+1684' },
  { name: 'Andorra', code: '+376' },
  { name: 'Angola', code: '+244' },
  { name: 'Anguilla', code: '+1264' },
  { name: 'Antarctica', code: '+672' },
  { name: 'Antigua and Barbuda', code: '+1268' },
  { name: 'Argentina', code: '+54' },
  { name: 'Armenia', code: '+374' },
  { name: 'Aruba', code: '+297' },
  // { name: 'Australia', code: '+61' },
  { name: 'Austria', code: '+43' },
  { name: 'Azerbaijan', code: '+994' },
  { name: 'Bahamas', code: '+1242' },
  { name: 'Bahrain', code: '+973' },
  { name: 'Bangladesh', code: '+880' },
  { name: 'Barbados', code: '+1246' },
  { name: 'Belarus', code: '+375' },
  { name: 'Belgium', code: '+32' },
  { name: 'Belize', code: '+501' },
  { name: 'Benin', code: '+229' },
  { name: 'Bermuda', code: '+1441' },
  { name: 'Bhutan', code: '+975' },
  { name: 'Bolivia, Plurinational State of', code: '+591' },
  { name: 'Bosnia and Herzegovina', code: '+387' },
  { name: 'Botswana', code: '+267' },
  { name: 'Brazil', code: '+55' },
  { name: 'British Indian Ocean Territory', code: '+246' },
  { name: 'Brunei Darussalam', code: '+673' },
  { name: 'Bulgaria', code: '+359' },
  { name: 'Burkina Faso', code: '+226' },
  { name: 'Burundi', code: '+257' },
  { name: 'Cambodia', code: '+855' },
  { name: 'Cameroon', code: '+237' },
  // { name: 'Canada', code: '+1' },
  { name: 'Cape Verde', code: '+238' },
  { name: 'Cayman Islands', code: '+ 345' },
  { name: 'Central African Republic', code: '+236' },
  { name: 'Chad', code: '+235' },
  { name: 'Chile', code: '+56' },
  { name: 'China', code: '+86' },
  { name: 'Christmas Island', code: '+61' },
  { name: 'Cocos (Keeling) Islands', code: '+61' },
  { name: 'Colombia', code: '+57' },
  { name: 'Comoros', code: '+269' },
  { name: 'Congo', code: '+242' },
  { name: 'Congo, The Democratic Republic of the Congo', code: '+243' },
  { name: 'Cook Islands', code: '+682' },
  { name: 'Costa Rica', code: '+506' },
  { name: "Cote d'Ivoire", code: '+225' },
  { name: 'Croatia', code: '+385' },
  { name: 'Cuba', code: '+53' },
  { name: 'Cyprus', code: '+357' },
  { name: 'Czech Republic', code: '+420' },
  { name: 'Denmark', code: '+45' },
  { name: 'Djibouti', code: '+253' },
  { name: 'Dominica', code: '+1767' },
  { name: 'Dominican Republic', code: '+1849' },
  { name: 'Ecuador', code: '+593' },
  { name: 'Egypt', code: '+20' },
  { name: 'El Salvador', code: '+503' },
  { name: 'Equatorial Guinea', code: '+240' },
  { name: 'Eritrea', code: '+291' },
  { name: 'Estonia', code: '+372' },
  { name: 'Ethiopia', code: '+251' },
  { name: 'Falkland Islands (Malvinas)', code: '+500' },
  { name: 'Faroe Islands', code: '+298' },
  { name: 'Fiji', code: '+679' },
  { name: 'Finland', code: '+358' },
  { name: 'France', code: '+33' },
  { name: 'French Guiana', code: '+594' },
  { name: 'French Polynesia', code: '+689' },
  { name: 'Gabon', code: '+241' },
  { name: 'Gambia', code: '+220' },
  { name: 'Georgia', code: '+995' },
  // { name: 'Germany', code: '+49' },
  { name: 'Ghana', code: '+233' },
  { name: 'Gibraltar', code: '+350' },
  { name: 'Greece', code: '+30' },
  { name: 'Greenland', code: '+299' },
  { name: 'Grenada', code: '+1473' },
  { name: 'Guadeloupe', code: '+590' },
  { name: 'Guam', code: '+1671' },
  { name: 'Guatemala', code: '+502' },
  { name: 'Guernsey', code: '+44' },
  { name: 'Guinea', code: '+224' },
  { name: 'Guinea-Bissau', code: '+245' },
  { name: 'Guyana', code: '+595' },
  { name: 'Haiti', code: '+509' },
  { name: 'Holy See (Vatican City State)', code: '+379' },
  { name: 'Honduras', code: '+504' },
  // { name: 'Hong Kong', code: '+852' },
  { name: 'Hungary', code: '+36' },
  { name: 'Iceland', code: '+354' },
  { name: 'India', code: '+91' },
  { name: 'Indonesia', code: '+62' },
  { name: 'Iran, Islamic Republic of Persian Gulf', code: '+98' },
  { name: 'Iraq', code: '+964' },
  { name: 'Ireland', code: '+353' },
  { name: 'Isle of Man', code: '+44' },
  { name: 'Israel', code: '+972' },
  { name: 'Italy', code: '+39' },
  { name: 'Jamaica', code: '+1876' },
  // { name: 'Japan', code: '+81' },
  { name: 'Jersey', code: '+44' },
  { name: 'Jordan', code: '+962' },
  { name: 'Kazakhstan', code: '+77' },
  { name: 'Kenya', code: '+254' },
  { name: 'Kiribati', code: '+686' },
  {
    name: "Korea, Democratic People's Republic of Korea",
    code: '+850',
  },
  //{ name: 'Korea, Republic of South Korea', code: '+82' },
  { name: 'Kuwait', code: '+965' },
  { name: 'Kyrgyzstan', code: '+996' },
  { name: 'Laos', code: '+856' },
  { name: 'Latvia', code: '+371' },
  { name: 'Lebanon', code: '+961' },
  { name: 'Lesotho', code: '+266' },
  { name: 'Liberia', code: '+231' },
  { name: 'Libyan Arab Jamahiriya', code: '+218' },
  { name: 'Liechtenstein', code: '+423' },
  { name: 'Lithuania', code: '+370' },
  { name: 'Luxembourg', code: '+352' },
  { name: 'Macao', code: '+853' },
  { name: 'Macedonia', code: '+389' },
  { name: 'Madagascar', code: '+261' },
  { name: 'Malawi', code: '+265' },
  { name: 'Malaysia', code: '+60' },
  { name: 'Maldives', code: '+960' },
  { name: 'Mali', code: '+223' },
  { name: 'Malta', code: '+356' },
  { name: 'Marshall Islands', code: '+692' },
  { name: 'Martinique', code: '+596' },
  { name: 'Mauritania', code: '+222' },
  { name: 'Mauritius', code: '+230' },
  { name: 'Mayotte', code: '+262' },
  { name: 'Mexico', code: '+52' },
  {
    name: 'Micronesia, Federated States of Micronesia',
    code: '+691',
  },
  { name: 'Moldova', code: '+373' },
  { name: 'Monaco', code: '+377' },
  { name: 'Mongolia', code: '+976' },
  { name: 'Montenegro', code: '+382' },
  { name: 'Montserrat', code: '+1664' },
  { name: 'Morocco', code: '+212' },
  { name: 'Mozambique', code: '+258' },
  { name: 'Myanmar', code: '+95' },
  { name: 'Namibia', code: '+264' },
  { name: 'Nauru', code: '+674' },
  { name: 'Nepal', code: '+977' },
  // { name: 'Netherlands', code: '+31' },
  { name: 'Netherlands Antilles', code: '+599' },
  { name: 'New Caledonia', code: '+687' },
  { name: 'New Zealand', code: '+64' },
  { name: 'Nicaragua', code: '+505' },
  { name: 'Niger', code: '+227' },
  { name: 'Nigeria', code: '+234' },
  { name: 'Niue', code: '+683' },
  { name: 'Norfolk Island', code: '+672' },
  { name: 'Northern Mariana Islands', code: '+1670' },
  { name: 'Norway', code: '+47' },
  { name: 'Oman', code: '+968' },
  { name: 'Pakistan', code: '+92' },
  { name: 'Palau', code: '+680' },
  { name: 'Palestinian Territory, Occupied', code: '+970' },
  { name: 'Panama', code: '+507' },
  { name: 'Papua New Guinea', code: '+675' },
  { name: 'Paraguay', code: '+595' },
  { name: 'Peru', code: '+51' },
  { name: 'Philippines', code: '+63' },
  { name: 'Pitcairn', code: '+872' },
  { name: 'Poland', code: '+48' },
  { name: 'Portugal', code: '+351' },
  { name: 'Puerto Rico', code: '+1939' },
  { name: 'Qatar', code: '+974' },
  { name: 'Romania', code: '+40' },
  { name: 'Russia', code: '+7' },
  { name: 'Rwanda', code: '+250' },
  { name: 'Reunion', code: '+262' },
  { name: 'Saint Barthelemy', code: '+590' },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    code: '+290',
  },
  { name: 'Saint Kitts and Nevis', code: '+1869' },
  { name: 'Saint Lucia', code: '+1758' },
  { name: 'Saint Martin', code: '+590' },
  { name: 'Saint Pierre and Miquelon', code: '+508' },
  { name: 'Saint Vincent and the Grenadines', code: '+1784' },
  { name: 'Samoa', code: '+685' },
  { name: 'San Marino', code: '+378' },
  { name: 'Sao Tome and Principe', code: '+239' },
  { name: 'Saudi Arabia', code: '+966' },
  { name: 'Senegal', code: '+221' },
  { name: 'Serbia', code: '+381' },
  { name: 'Seychelles', code: '+248' },
  { name: 'Sierra Leone', code: '+232' },
  // { name: 'Singapore', code: '+65' },
  { name: 'Slovakia', code: '+421' },
  { name: 'Slovenia', code: '+386' },
  { name: 'Solomon Islands', code: '+677' },
  { name: 'Somalia', code: '+252' },
  { name: 'South Africa', code: '+27' },
  { name: 'South Sudan', code: '+211' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: '+500',
  },
  { name: 'Spain', code: '+34' },
  { name: 'Sri Lanka', code: '+94' },
  { name: 'Sudan', code: '+249' },
  { name: 'Suriname', code: '+597' },
  { name: 'Svalbard and Jan Mayen', code: '+47' },
  { name: 'Swaziland', code: '+268' },
  { name: 'Sweden', code: '+46' },
  { name: 'Switzerland', code: '+41' },
  { name: 'Syrian Arab Republic', code: '+963' },
  { name: 'Taiwan', code: '+886' },
  { name: 'Tajikistan', code: '+992' },
  { name: 'Tanzania, United Republic of Tanzania', code: '+255' },
  { name: 'Thailand', code: '+66' },
  { name: 'Timor-Leste', code: '+670' },
  { name: 'Togo', code: '+228' },
  { name: 'Tokelau', code: '+690' },
  { name: 'Tonga', code: '+676' },
  { name: 'Trinidad and Tobago', code: '+1868' },
  { name: 'Tunisia', code: '+216' },
  { name: 'Turkey', code: '+90' },
  { name: 'Turkmenistan', code: '+993' },
  { name: 'Turks and Caicos Islands', code: '+1649' },
  { name: 'Tuvalu', code: '+688' },
  { name: 'Uganda', code: '+256' },
  { name: 'Ukraine', code: '+380' },
  { name: 'United Arab Emirates', code: '+971' },
  // { name: 'United Kingdom', code: '+44' },
  //{ name: 'United States', code: '+1' },
  { name: 'Uruguay', code: '+598' },
  { name: 'Uzbekistan', code: '+998' },
  { name: 'Vanuatu', code: '+678' },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    code: '+58',
  },
  // { name: 'Vietnam', code: '+84' },
  { name: 'Virgin Islands, British', code: '+1284' },
  { name: 'Virgin Islands, U.S.', code: '+1340' },
  { name: 'Wallis and Futuna', code: '+681' },
  { name: 'Yemen', code: '+967' },
  { name: 'Zambia', code: '+260' },
  { name: 'Zimbabwe', code: '+263' },
]

export const TODO_TYPES = {
  HOMEWORK: 'Homework',
  PREP: 'Prep',
  REVIEW: 'Review',
  HOMEWORK_KO: '숙제',
  PREP_KO: '예습',
  REVIEW_KO: '복습',
}

export const COURSE_TYPES = {
  UPLOAD_FILE: 'upload_file',
  UPLOAD_YOUTUBE: 'upload_youtube',
  UPLOAD_ARTICLE: 'upload_article',
  DISCUSSION: 'discussion',
}

export const STUDENT_TYPE = {
  PLUS: 'plus',
  TEENS: 'teens',
}

export const LOWER_HASHTAG_ID = {
  FOR_TEENS: 82,
}

// 현재 프로모션 기간 여부
export const IS_NOW_PROMO = false

export const TEENS_PROD_DOMAIN = 'https://www.ringleteens.com'
export const TEENS_APPLE_APPID = 'com.ringleteens'

// Zoom Help zoom_tooltip 최초 1회 노출 여부
export const ZOOM_HELP_TOOLTIP = 'zoom_help_tooltip'

// login 요청 시 request의 username 을 구성하는 prefix
export const SOCIAL_LOGIN_PREFIX = 'snslogin:'
export const EMAIL_LOGIN_PREFIX = 'email:'

export const CURRENCY = {
  KRW: 'krw',
  USD: 'usd',
}

export const COUNTRY_ALPHA2_CODE = {
  KR: 'KR',
  US: 'US',
}

export const CURRENCY_LIST = [
  {
    country_code: COUNTRY_ALPHA2_CODE.US,
    name: 'USD',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.KR,
    name: 'KRW',
  },
]
export const PRICING_CURRENCY_OPTIONS = [
  {
    country_code: COUNTRY_ALPHA2_CODE.US,
    name: '$ USD',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.KR,
    name: '₩ KRW',
  },
]
