import { useToast } from '@repo/design-system/index'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useRouter } from 'next/router'
import Cookies from 'universal-cookie'
import { CurrentUser, IEventPropsUserInfo } from '../../../model/Common/common.interface'
import { IProfile, IProfileUserAbout } from '../../../model/profile.interface'
import { IProfilePayload, IStudyingEnglishPurpose } from '../../../model/trial.interface'
import { getJwtToken, useJwtToken } from '../../../modules/auth'
import useTranslation from '../../../modules/i18n/useTranslation'
import apis from '../../apis'
import { LocaleType } from '../../i18n/config'
import { s_edit_profile } from '../../i18n/strings/auto/wording'
import { UpcomingLessons } from '../../lessonTypes'
import {
  eventPropsUserInfoQueryApi,
  productsB2BQueryApi,
  productsB2CQueryApi,
  profileQueryApi,
  recommendLessonTimeQueryApiV5,
  trialStatusQueryApi,
  upcomingsCountQueryApi,
  upcomingsQueryApi,
  upcomingsQueryApiV5,
  userQueryApi,
} from '../../react-query/queryApis'
import { COOKIE_LIST, COOKIE_OPT } from '../../vars'
import { createAxiosTokenConfig, handleAxiosError } from '../axios-util'

// * =========================================================================== */
// *
// * USE-QUERY *
// *
// * =========================================================================== */

export const useUser = () => {
  const token = getJwtToken()
  const cookie = new Cookies()
  const caf_token = cookie.get(COOKIE_LIST.CAF_TOKEN)
  return useQuery<CurrentUser, AxiosError>({
    queryKey: userQueryApi.getQueryKey(),
    queryFn: userQueryApi.getQueryFn(),

    staleTime: 1000 * 60 * 24,
    cacheTime: 1000 * 60 * 24,
    retry: false,
    enabled: !!token && !caf_token,
  })
}

export const useEventPropsUserInfo = () => {
  const token = getJwtToken()

  return useQuery<IEventPropsUserInfo, AxiosError>({
    queryKey: eventPropsUserInfoQueryApi.getQueryKey(),
    queryFn: eventPropsUserInfoQueryApi.getQueryFn(),

    staleTime: 1000 * 60 * 24,
    cacheTime: 1000 * 60 * 24,
    enabled: !!token,
  })
}

export const useProfile = () => {
  return useQuery<IProfile, AxiosError>({
    queryKey: profileQueryApi.getQueryKey(),
    queryFn: profileQueryApi.getQueryFn(),

    // onError: (e) => handleAxiosError(e),
  })
}

export const useUpcomings = () => {
  return useQuery<any, AxiosError>({
    queryKey: upcomingsQueryApi.getQueryKey(),
    queryFn: upcomingsQueryApi.getQueryFn(),
    // onError: (e) => handleAxiosError(e),
  })
}

export const useUpcomingsV5 = () => {
  const token = useJwtToken()
  return useQuery<UpcomingLessons, AxiosError>({
    queryKey: upcomingsQueryApiV5.getQueryKey(),
    queryFn: upcomingsQueryApiV5.getQueryFn(),
    // onError: (e) => handleAxiosError(e),
    refetchOnWindowFocus: true,
    enabled: !!token
  })
}

export const useRecommendLessonTime = () => {
  return useQuery<any, AxiosError>({
    queryKey: recommendLessonTimeQueryApiV5.getQueryKey(),
    queryFn: recommendLessonTimeQueryApiV5.getQueryFn(),
    // onError: (e) => handleAxiosError(e),
    cacheTime: 1000 * 30,
    staleTime: 1000 * 30,
    refetchOnWindowFocus: false,
  })
}

export const useUpcomingsCount = () => {
  const token = getJwtToken()

  return useQuery<any, AxiosError>({
    queryKey: upcomingsCountQueryApi.getQueryKey(),
    queryFn: upcomingsCountQueryApi.getQueryFn(),

    // onError: (e) => handleAxiosError(e),
    staleTime: 1000 * 60 * 24,
    cacheTime: 1000 * 60 * 24,
    retry: false,
    enabled: !!token,
  })
}

// * =========================================================================== */
// *
// * USE-MUTATION *
// *
// * =========================================================================== */

interface useChangeLocalePayload {
  locale: LocaleType
}

interface useChangeLocaleResponse {
  success: boolean
}

export const useChangeLocale = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { toastSuccess, toastError } = useToast()
  const token = getJwtToken()

  const postUrl = apis.auth.changeLocale()
  const queryKey = userQueryApi.getQueryKey()

  return useMutation<AxiosResponse<useChangeLocaleResponse>, AxiosError, useChangeLocalePayload, { prev: CurrentUser }>(
    {
      mutationFn: (payload) => axios.post<useChangeLocaleResponse>(postUrl, payload, createAxiosTokenConfig(token)),

      onMutate: async (payload) => {
        await queryClient.cancelQueries(queryKey)

        const prev = queryClient.getQueryData<CurrentUser>(queryKey)

        queryClient.setQueryData<CurrentUser>(queryKey, (prevData) => {
          return {
            ...prevData,
            locale: payload.locale,
          }
        })

        return { prev }
      },

      onError: (error, payload, context) => {
        const { prev } = context
        console.log('MUTATION ERROR :: ', error, error.response)

        queryClient.setQueryData<CurrentUser>(queryKey, prev)
      },

      onSuccess: (response, payload, context) => {
        const { data } = response
        console.log('MUTATION SUCCESS ::', data)

        const cookies = new Cookies()
        cookies.set('locale', payload.locale, COOKIE_OPT)

        toastSuccess(t(s_edit_profile.it_has_been_saved))
      },

      onSettled: (response, error, payload, context) => {
        console.log('QUERY INVALIDATE ::', queryKey)
        queryClient.invalidateQueries(queryKey)

        if (error) handleAxiosError(error)
      },
    }
  )
}

// ! =========================================================================== */

interface useChangePasswordPayload {
  password: string
}

interface useChangePasswordReponse {
  message: string
  success: boolean
}

export const useChangePassword = () => {
  const router = useRouter()
  const queryClient = useQueryClient()
  const token = getJwtToken()

  const userId = router.query?.userId
    ? typeof router.query.userId === 'string'
      ? parseInt(router.query.userId, 10)
      : 0
    : undefined

  const postUrl = apis.profile.password(userId)
  const queryKey = userQueryApi.getQueryKey()

  return useMutation<AxiosResponse<useChangePasswordReponse>, AxiosError, useChangePasswordPayload>({
    mutationFn: (payload) => axios.post<useChangePasswordReponse>(postUrl, payload, createAxiosTokenConfig(token)),

    onMutate: async (payload) => {
      await queryClient.cancelQueries(queryKey)
    },

    onError: (error, payload, context) => {
      console.log('MUTATION ERROR :: ', error, error.response)
    },

    onSuccess: (response, payload, context) => {
      const { data } = response
      console.log('MUTATION SUCCESS ::', data)
    },

    onSettled: (response, error, payload, context) => {
      console.log('QUERY INVALIDATE ::', queryKey)
      queryClient.invalidateQueries(queryKey)

      if (error) handleAxiosError(error)
    },
  })
}

// ! =========================================================================== */

type useUpdateProfilePayload =
  | basicProfilePayload
  | emailPayload
  | timezonePayload
  | teensProfilePayload
  | cetificatePaylaod

interface baseProfilePayload {
  child_id: number
}

interface basicProfilePayload extends baseProfilePayload {
  korean_name: string
  first_name: string
  last_name: string
  company: string
  company_code: string
  company_work: string
  timezone: string
  birth_day: string
}
interface cetificatePaylaod {
  korean_name: string
  first_name: string
  last_name: string
}
interface teensProfilePayload extends baseProfilePayload {
  first_name: string
  last_name: string
  birth_day: string
}

interface emailPayload extends baseProfilePayload {
  email2: string
  email_accept: boolean
  email_accept_2: boolean
}

interface timezonePayload extends baseProfilePayload {
  timezone: string
}

interface useUpdateProfileResponse {
  message: string
  success: boolean
  user: {
    id: number
    image_url: string
    timezone: string
  }
}

export const useUpdateProfile = () => {
  const queryClient = useQueryClient()
  const token = getJwtToken()

  const postUrl = apis.profile.get()
  const queryKey = profileQueryApi.getQueryKey()

  return useMutation<AxiosResponse<useUpdateProfileResponse>, AxiosError, useUpdateProfilePayload, { prev: IProfile }>({
    mutationFn: (payload) => axios.post<useUpdateProfileResponse>(postUrl, payload, createAxiosTokenConfig(token)),

    onMutate: async (payload) => {
      await queryClient.cancelQueries(queryKey)

      const prev = queryClient.getQueryData<IProfile>(queryKey)

      queryClient.setQueryData<IProfile>(queryKey, (prevData) => {
        return {
          ...prevData,
          user: {
            ...prevData?.user,
            ...payload,
          },
        }
      })

      return { prev }
    },

    onError: (error, payload, context) => {
      const { prev } = context
      console.log('MUTATION ERROR :: ', error, error.response)

      queryClient.setQueryData<IProfile>(queryKey, prev)
    },

    onSuccess: (response, payload, context) => {
      const { data } = response
      console.log('MUTATION SUCCESS ::', data)
    },

    onSettled: (response, error, payload, context) => {
      console.log('QUERY INVALIDATE ::', queryKey)
      queryClient.invalidateQueries(queryKey)

      if (error) handleAxiosError(error)
    },
  })
}

// ! =========================================================================== */

interface useUpdateProfileResponse {
  show_point_modal: boolean
  student_info: {
    created_at: string
    first_name: string
    id: number
    is_public: boolean
    job_id: number
    last_name: string
    lesson_purpose_ids: number[]
    other_job: string
    status: number
    updated_at: string
    user_id: number
    video_id: number
  }
  success: boolean
}

export interface useUpdateProfileInfoPayload extends IProfilePayload {
  first_name?: string
  last_name?: string
  job_id?: number
  studying_english_purposes?: IStudyingEnglishPurpose[]
  focus_of_improvement_ids?: number[]
  interest_keyword_ids?: number[]
  abouts?: IProfileUserAbout[]
  submit?: boolean
}

interface MutationVariables {
  payload: useUpdateProfileInfoPayload
  token?: string
}

export const useUpdateProfileInfo = () => {
  const queryClient = useQueryClient()
  const { locale } = useTranslation()
  const postUrl = apis.profile.info()
  const searchParams = { locale, duration: 20 }
  const queryKey = trialStatusQueryApi.getQueryKey({ searchParams })

  return useMutation<AxiosResponse<useUpdateProfileResponse>, AxiosError, MutationVariables>({
    mutationFn: ({ payload, token }) => {
      const jwtToken = token || getJwtToken()
      return axios.post<useUpdateProfileResponse>(postUrl, payload, createAxiosTokenConfig(jwtToken))
    },

    onMutate: async ({ payload, token }) => {
      await queryClient.cancelQueries(queryKey)
    },

    onError: (error, { payload, token }, context) => {
      console.log('MUTATION ERROR :: ', error, error.response)
    },

    onSuccess: (response, { payload, token }, context) => {
      const { data } = response
      console.log('MUTATION SUCCESS ::', data)
    },

    onSettled: (response, error, { payload, token }, context) => {
      console.log('QUERY INVALIDATE ::', queryKey)
      queryClient.invalidateQueries(queryKey)

      if (error) handleAxiosError(error)
    },
  })
}

// ! =========================================================================== */
interface useUpdatePhonePayload {
  code: string
  phone_number: string
  activeChildId: number
}

interface useUpdatePhoneResponse {
  message: string
  success: boolean
}

export const useUpdatePhone = () => {
  const queryClient = useQueryClient()
  const { locale } = useTranslation()
  const token = getJwtToken()

  const postUrl = apis.profile.phoneCodeAuthorized(locale)
  const queryKey = profileQueryApi.getQueryKey()

  return useMutation<AxiosResponse<useUpdatePhoneResponse>, AxiosError, useUpdatePhonePayload>({
    mutationFn: (payload) => axios.post<useUpdatePhoneResponse>(postUrl, payload, createAxiosTokenConfig(token)),

    onMutate: async (payload) => {
      await queryClient.cancelQueries(queryKey)
    },

    onError: (error, payload, context) => {
      console.log('MUTATION ERROR :: ', error, error.response)
    },

    onSuccess: (response, payload, context) => {
      const { data } = response
      console.log('MUTATION SUCCESS ::', data)
    },

    onSettled: (response, error, payload, context) => {
      console.log('QUERY INVALIDATE ::', queryKey)
      queryClient.invalidateQueries(queryKey)
    },
  })
}

// ! =========================================================================== */

interface useVerifyCompanyCodePayload {
  promo_code: string
}

interface useVerifyCompanyCodeResponse {
  message: string
  success: string
  response: {
    code_type: string
    by_pass_promo_code: string
  }
}
export const useVerifyCompanyCode = () => {
  const queryClient = useQueryClient()
  const token = getJwtToken()

  const postUrl = apis.auth.checkPromoCode()
  const queryKey = userQueryApi.getQueryKey()

  return useMutation<AxiosResponse<useVerifyCompanyCodeResponse>, AxiosError, useVerifyCompanyCodePayload>({
    mutationFn: (payload) => axios.post<useVerifyCompanyCodeResponse>(postUrl, payload, createAxiosTokenConfig(token)),

    onMutate: async (payload) => {
      await queryClient.cancelQueries(queryKey)
    },

    onError: (error, payload, context) => {
      console.log('MUTATION ERROR :: ', error, error.response)
    },

    onSuccess: (response, payload, context) => {
      const { data } = response
      console.log('MUTATION SUCCESS ::', data)

      queryClient.invalidateQueries(productsB2CQueryApi.getQueryKey())
      queryClient.invalidateQueries(productsB2BQueryApi.getQueryKey())
    },

    onSettled: (response, error, payload, context) => {
      console.log('QUERY INVALIDATE ::', queryKey)
      queryClient.invalidateQueries(queryKey)

      if (error) handleAxiosError(error)
    },
  })
}

// ! =========================================================================== */
