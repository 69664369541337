import classNames from 'classnames'
import { createContext, MouseEventHandler, useContext } from 'react'
import {
  DSRADIO_BUTTON_POSITION,
  DSRadioContextProps,
  DSRadioItemButtonProps,
  DSRadioItemProps,
  DSRadioProps,
} from './dsradio.interface'
import { getBorderColorClassName, getButtonType, getInnerCircleColorClassName } from './dsRadioHelper'

const DSRadioContext = createContext<DSRadioContextProps>({
  selected: null,
  setSelected: null,
  buttonPosition: null,
  disabledAll: null,
  activeColor: '',
})

const DSRadio = ({
  controlSelected,
  buttonPosition = DSRADIO_BUTTON_POSITION.LEFT,
  activeColor = '',
  containerClassName = '',
  disabled = false,
  children,
}: DSRadioProps) => {
  const [selected, setSelected] = controlSelected

  return (
    <DSRadioContext.Provider
      value={{
        selected,
        setSelected,
        buttonPosition,
        disabledAll: disabled,
        activeColor,
      }}
    >
      <div className={containerClassName}>{children}</div>
    </DSRadioContext.Provider>
  )
}

export default DSRadio

//

const ItemButton = ({ type, activeColor = '' }: DSRadioItemButtonProps) => {
  const borderColorClassName = getBorderColorClassName(type)
  const innerCircleColorClassName = getInnerCircleColorClassName(type)

  return (
    <div
      className={classNames(
        'border-1 flex h-[20px] w-[20px] items-center justify-center rounded-full',
        borderColorClassName
      )}
    >
      {innerCircleColorClassName && (
        <div
          className={classNames('h-[11px] w-[11px] rounded-full', innerCircleColorClassName)}
          style={activeColor ? { backgroundColor: activeColor } : {}}
        />
      )}
    </div>
  )
}

const Item = ({ value, disabled: disabledItem = false, children = undefined, className = '' }: DSRadioItemProps) => {
  const context = useContext(DSRadioContext)
  if (!context) throw new Error('RadioItem')
  const { selected, setSelected, buttonPosition, disabledAll, activeColor } = context

  const checked = value === selected
  const disabled = disabledAll || disabledItem
  const type = getButtonType(checked, disabled)
  const reversed = buttonPosition === DSRADIO_BUTTON_POSITION.RIGHT
  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    if (!disabled) setSelected(value)
  }

  return (
    <div
      className={classNames(
        'flex items-center',
        reversed ? 'flex-row-reverse justify-between' : 'w-max flex-row',
        disabled || 'cursor-pointer',
        className
      )}
      onClick={onClick}
    >
      <ItemButton type={type} activeColor={activeColor} />
      <div className={classNames('mx-[6px]', disabled && 'text-gray-300')}>{children ?? value}</div>
    </div>
  )
}
DSRadio.Item = Item
