import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useRouter } from 'next/router'
import { Cookies } from 'react-cookie'
import { CurrentUser, IEventPropsUserInfo } from '../../../model/Common/common.interface'
import { IProfile, IProfileInfo, IProfileLessonPurpose, IProfileUserAbout } from '../../../model/profile.interface'
import { getIdFromJwtToken, getJwtToken } from '../../../modules/auth'
import apis from '../../apis'
import { LocaleType } from '../../i18n/config'
import {
  eventPropsUserInfoQueryApi,
  profileInfoQueryApi,
  profileQueryApi,
  timezoneListApi,
  upcomingsCountQueryApi,
  userQueryApi,
} from '../../react-query/queryApis'
import { COOKIE_LIST, COOKIE_OPT, COOKIE_OPT_FOR_ACCESS_TOKEN } from '../../vars'
import { createAxiosTokenConfig, handleAxiosError } from '../axios-util'
import { IProfileOption } from './../../../model/profile.interface'

// * =========================================================================== */
// *
// * USE-QUERY *
// *
// * =========================================================================== */

export const useUser = () => {
  const cookies = new Cookies()
  const token = cookies.get(COOKIE_LIST.JWT_TOKEN)
  const userId = getIdFromJwtToken(token)
  return useQuery<CurrentUser, AxiosError>({
    queryKey: userQueryApi.getQueryKey(userId),
    queryFn: userQueryApi.getQueryFn(),

    staleTime: 1000 * 60 * 24,
    cacheTime: 1000 * 60 * 24,
    retry: false,
    enabled: !!token, // 토큰 없으면 data undefined, 토큰 있으면 무조건 내려옴 (잘못된 토큰이면 에러)
    // onError
    // eslint-disable-next-line @tanstack/query/no-deprecated-options
    onError: (error) => {
      cookies.remove(COOKIE_LIST.JWT_TOKEN, COOKIE_OPT_FOR_ACCESS_TOKEN)
      cookies.remove(COOKIE_LIST.SESSION_ROLE, COOKIE_OPT)
      cookies.remove(COOKIE_LIST.PARENT_TOKEN, COOKIE_OPT)
      if (typeof window !== 'undefined') window.location.reload()
    },
  })
}

interface ITimezoneResponse {
  current_timezone: string
  success: boolean
  timezone_list: string[]
}
export const useTimezoneList = () => {
  const cookies = new Cookies()
  const token = cookies.get(COOKIE_LIST.JWT_TOKEN)
  return useQuery<ITimezoneResponse, AxiosError>({
    queryKey: timezoneListApi.getQueryKey(),
    queryFn: timezoneListApi.getQueryFn(),
  })
}

export const useEventPropsUserInfo = () => {
  const token = getJwtToken()

  return useQuery<IEventPropsUserInfo, AxiosError>({
    queryKey: eventPropsUserInfoQueryApi.getQueryKey(),
    queryFn: eventPropsUserInfoQueryApi.getQueryFn(),

    staleTime: 1000 * 60 * 24,
    cacheTime: 1000 * 60 * 24,
    enabled: !!token,
  })
}

export const useProfile = () => {
  const router = useRouter()

  const userId = router.query?.userId
    ? typeof router.query.userId === 'string'
      ? parseInt(router.query.userId, 10)
      : 0
    : undefined
  const searchParams = userId ? { child_id: userId } : undefined

  return useQuery<IProfile, AxiosError>({
    queryKey: profileQueryApi.getQueryKey({ searchParams }),
    queryFn: profileQueryApi.getQueryFn({ searchParams }),

    // onError: (e) => handleAxiosError(e),
  })
}

export const useProfileInfo = () => {
  const router = useRouter()

  const userId = router.query?.userId
    ? typeof router.query.userId === 'string'
      ? parseInt(router.query.userId, 10)
      : 0
    : undefined
  const searchParams = userId ? { child_id: userId } : undefined

  return useQuery<IProfileInfo, AxiosError>({
    queryKey: profileInfoQueryApi.getQueryKey({ searchParams }),
    queryFn: profileInfoQueryApi.getQueryFn({ searchParams }),

    // onError: (e) => handleAxiosError(e),
  })
}

export const useUpcomingsCount = () => {
  const token = getJwtToken()

  return useQuery<any, AxiosError>({
    queryKey: upcomingsCountQueryApi.getQueryKey(),
    queryFn: upcomingsCountQueryApi.getQueryFn(),

    // onError: (e) => handleAxiosError(e),
    staleTime: 1000 * 60 * 24,
    cacheTime: 1000 * 60 * 24,
    retry: false,
    enabled: !!token,
  })
}

// * =========================================================================== */
// *
// * USE-MUTATION *
// *
// * =========================================================================== */

interface useChangeLocalePayload {
  locale: LocaleType
}

interface useChangeLocaleResponse {
  success: boolean
}

export const useChangeLocale = ({ ...options } = {}) => {
  const cookies = new Cookies()
  const token = cookies.get(COOKIE_LIST.JWT_TOKEN)
  const queryClient = useQueryClient()

  const userId = getIdFromJwtToken(token)

  const putUrl = apis.account()
  const queryKey = userQueryApi.getQueryKey(userId)

  return useMutation<AxiosResponse<useChangeLocaleResponse>, AxiosError, useChangeLocalePayload, { prev: CurrentUser }>(
    {
      mutationFn: (payload) => axios.put<useChangeLocaleResponse>(putUrl, payload, createAxiosTokenConfig(token)),

      onMutate: async (payload) => {
        await queryClient.cancelQueries(queryKey)

        const prev = queryClient.getQueryData<CurrentUser>(queryKey)

        queryClient.setQueryData<CurrentUser>(queryKey, (prevData) => {
          return {
            ...prevData,
            locale: payload.locale,
          }
        })

        return { prev }
      },

      onError: (error, payload, context) => {
        const { prev } = context
        console.log('MUTATION ERROR :: ', error, error.response)

        queryClient.setQueryData<CurrentUser>(queryKey, prev)
      },

      onSuccess: (response, payload, context) => {
        const { data } = response
        console.log('MUTATION SUCCESS ::', data)

        const cookies = new Cookies()
        cookies.set(COOKIE_LIST.LOCALE, payload.locale, COOKIE_OPT)
      },

      onSettled: (response, error, payload, context) => {
        console.log('QUERY INVALIDATE ::', queryKey)
        queryClient.invalidateQueries(queryKey)

        if (error) handleAxiosError(error)
      },
      ...options,
    }
  )
}

interface useChangeTimezonePayload {
  timezone: string
}

interface useChangeTimezoneResponse {
  success: boolean
}

export const useChangeTimezone = () => {
  const cookies = new Cookies()
  const token = cookies.get(COOKIE_LIST.JWT_TOKEN)
  const queryClient = useQueryClient()

  const userId = getIdFromJwtToken(token)

  const putUrl = apis.account()
  const queryKey = userQueryApi.getQueryKey(userId)

  return useMutation<
    AxiosResponse<useChangeTimezoneResponse>,
    AxiosError,
    useChangeTimezonePayload,
    { prev: CurrentUser }
  >({
    mutationFn: (payload) => axios.put<useChangeTimezoneResponse>(putUrl, payload, createAxiosTokenConfig(token)),

    onMutate: async (payload) => {
      await queryClient.cancelQueries(queryKey)

      const prev = queryClient.getQueryData<CurrentUser>(queryKey)

      queryClient.setQueryData<CurrentUser>(queryKey, (prevData) => {
        return {
          ...prevData,
          timezone: payload.timezone,
        }
      })

      return { prev }
    },

    onError: (error, payload, context) => {
      const { prev } = context
      console.log('MUTATION ERROR :: ', error, error.response)

      queryClient.setQueryData<CurrentUser>(queryKey, prev)
    },

    onSuccess: (response, payload, context) => {
      const { data } = response
      console.log('MUTATION SUCCESS ::', data)

      const cookies = new Cookies()
      cookies.set('timezone', payload.timezone, COOKIE_OPT)
    },

    onSettled: (response, error, payload, context) => {
      console.log('QUERY INVALIDATE ::', queryKey)
      queryClient.invalidateQueries(queryKey)

      if (error) handleAxiosError(error)
    },
  })
}

// ! =========================================================================== */

interface useChangePasswordPayload {
  password: string
}

interface useChangePasswordReponse {
  message: string
  success: boolean
}

export const useChangePassword = () => {
  const router = useRouter()
  const queryClient = useQueryClient()
  const token = getJwtToken()

  const userId = router.query?.userId
    ? typeof router.query.userId === 'string'
      ? parseInt(router.query.userId, 10)
      : 0
    : undefined

  const postUrl = apis.profile.password(userId)
  const queryKey = userQueryApi.getQueryKey(userId)

  return useMutation<AxiosResponse<useChangePasswordReponse>, AxiosError, useChangePasswordPayload>({
    mutationFn: (payload) => axios.post<useChangePasswordReponse>(postUrl, payload, createAxiosTokenConfig(token)),

    onMutate: async (payload) => {
      await queryClient.cancelQueries(queryKey)
    },

    onError: (error, payload, context) => {
      console.log('MUTATION ERROR :: ', error, error.response)
    },

    onSuccess: (response, payload, context) => {
      const { data } = response
      console.log('MUTATION SUCCESS ::', data)
    },

    onSettled: (response, error, payload, context) => {
      console.log('QUERY INVALIDATE ::', queryKey)
      queryClient.invalidateQueries(queryKey)

      if (error) handleAxiosError(error)
    },
  })
}

// ! =========================================================================== */

type useUpdateProfilePayload =
  | basicProfilePayload
  | emailPayload
  | timezonePayload
  | teensProfilePayload
  | cetificatePaylaod

interface baseProfilePayload {
  child_id: number
}

interface basicProfilePayload extends baseProfilePayload {
  korean_name: string
  first_name: string
  last_name: string
  company: string
  company_code: string
  company_work: string
  timezone: string
  birth_day: string
}
interface cetificatePaylaod {
  korean_name: string
  first_name: string
  last_name: string
}
interface teensProfilePayload extends baseProfilePayload {
  first_name: string
  last_name: string
  birth_day: string
}

interface emailPayload extends baseProfilePayload {
  email2: string
  email_accept: boolean
  email_accept_2: boolean
}

interface timezonePayload extends baseProfilePayload {
  timezone: string
}

interface useUpdateProfileResponse {
  message: string
  success: boolean
  user: {
    id: number
    image_url: string
    timezone: string
  }
}

export const useUpdateProfile = () => {
  const router = useRouter()
  const queryClient = useQueryClient()
  const token = getJwtToken()

  const userId = router.query?.userId
    ? typeof router.query.userId === 'string'
      ? parseInt(router.query.userId, 10)
      : 0
    : undefined

  const postUrl = userId ? `${apis.profile.get()}?child_id=${userId}` : apis.profile.get()
  const searchParams = { child_id: userId }
  const queryKey = profileQueryApi.getQueryKey({ searchParams })

  return useMutation<AxiosResponse<useUpdateProfileResponse>, AxiosError, useUpdateProfilePayload, { prev: IProfile }>({
    mutationFn: (payload) => axios.post<useUpdateProfileResponse>(postUrl, payload, createAxiosTokenConfig(token)),

    onMutate: async (payload) => {
      await queryClient.cancelQueries(queryKey)

      const prev = queryClient.getQueryData<IProfile>(queryKey)

      queryClient.setQueryData<IProfile>(queryKey, (prevData) => {
        return {
          ...prevData,
          user: {
            ...prevData?.user,
            ...payload,
          },
        }
      })

      return { prev }
    },

    onError: (error, payload, context) => {
      const { prev } = context
      console.log('MUTATION ERROR :: ', error, error.response)

      queryClient.setQueryData<IProfile>(queryKey, prev)
    },

    onSuccess: (response, payload, context) => {
      const { data } = response
      console.log('MUTATION SUCCESS ::', data)
    },

    onSettled: (response, error, payload, context) => {
      console.log('QUERY INVALIDATE ::', queryKey)
      queryClient.invalidateQueries(queryKey)

      if (error) handleAxiosError(error)
    },
  })
}

// ! =========================================================================== */
interface useUpdateProfileResponse {
  show_point_modal: boolean
  student_info: {
    created_at: string
    first_name: string
    id: number
    is_public: boolean
    job_id: number
    last_name: string
    lesson_purpose_ids: number[]
    other_job: string
    status: number
    updated_at: string
    user_id: number
    video_id: number
  }
  success: boolean
}

interface useTeensUpdateProfileInfoPayload {
  first_name?: string
  last_name?: string
  lesson_purpose_ids?: number[]
  develop_skill_ids?: number[]
  material_topic_ids?: number[]
  interest_keyword_ids?: number[]
  other_lesson_purpose?: string
  abouts?: IProfileUserAbout[]
  primary_english?: boolean
  submit?: boolean
  email?: string
  english_level?: string
}
interface useTeensUpdateProfileResponse {
  user_info: {
    id: number
    first_name: string
    last_name: string
    email: string
    birth_day: string
    primary_english: boolean
    develop_skills: IProfileOption[]
    lesson_purposes: IProfileLessonPurpose
    material_topics: any
    interest_keywords: IProfileOption[]
    abouts: IProfileUserAbout[]
    point_status: number
    onboarding_status: number
    video: {
      video_url: string
      thumbnail_link: string | null
    }
  }
  success: boolean
}

//POST
export const useTeensUpdateProfileInfo = (child_id: number) => {
  const queryClient = useQueryClient()
  const token = getJwtToken()

  const postUrl = child_id ? `${apis.profile.info()}?child_id=${child_id}` : apis.profile.info()
  const searchParams = { child_id }
  const queryKey = profileInfoQueryApi.getQueryKey({ searchParams })

  return useMutation<AxiosResponse<useTeensUpdateProfileResponse>, AxiosError, useTeensUpdateProfileInfoPayload>({
    mutationFn: (payload) => axios.post<useTeensUpdateProfileResponse>(postUrl, payload, createAxiosTokenConfig(token)),

    onMutate: async (payload) => {
      await queryClient.cancelQueries(queryKey)
    },

    onError: (error, payload, context) => {
      console.log('MUTATION ERROR :: ', error, error.response)
    },

    onSuccess: (response, payload, context) => {
      const { data } = response
      console.log('MUTATION SUCCESS ::', data)

      queryClient.invalidateQueries(queryKey)
    },

    onSettled: (response, error, payload, context) => {
      console.log('QUERY INVALIDATE ::', queryKey)
      queryClient.invalidateQueries(queryKey)

      if (error) handleAxiosError(error)
    },
  })
}
