import { QIcon_checkbox_icon } from '@repo/icons/index'
import classNames from 'classnames'
import React from 'react'
import { COLORS } from '../../../modules/vars'
import { Selected } from '../../Radio/dsradio.interface'
import { useCheckboxState } from '../modules/CheckboxStateContext'
import { CHECKBOX_CHECKED, CHECKBOX_POSITION, CHECKBOX_SHAPE } from '../types'

export interface CheckboxItemProps {
  disabled?: boolean
  value: Selected
  children: React.ReactNode
  alignItem?: AlignItem
}
type AlignItem = 'items-start' | 'items-center' | 'items-end'
const CheckboxItem = ({
  value,
  disabled: disabledItem = false,
  children,
  alignItem = 'items-start',
}: CheckboxItemProps) => {
  const { shape, type, buttonPosition, disabledAll, selectedList, setSelectedList, checkedColor } = useCheckboxState() // Add checkedColor
  const checked = selectedList.includes(value)
  const reversed = buttonPosition === CHECKBOX_POSITION.RIGHT
  const disabled = disabledAll || disabledItem

  const handleSelectedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = e
    if (checked) {
      setSelectedList(selectedList.concat(value))
    } else {
      setSelectedList(selectedList.filter((checked) => checked !== value))
    }
  }

  // checked mode인 경우에 checkbox icon 색상
  const handleCheckedModeIconColor = () => {
    if (!checked && disabled) {
      return COLORS.GRAY200
    }
    if (!checked) {
      return COLORS.GRAY300
    }
    return COLORS.WHITE
  }

  // unchecked mode인 경우에 checkbox icon 색상
  const handleUnCheckedModeIconColor = () => {
    if (!checked) {
      return 'transparent'
    }

    return COLORS.WHITE
  }

  const handleCheckIconColor = () => {
    switch (type) {
      case CHECKBOX_CHECKED.UNCHECKED:
        return handleUnCheckedModeIconColor()
      case CHECKBOX_CHECKED.CHECKED:
      default:
        return handleCheckedModeIconColor()
    }
  }

  return (
    <label
      className={classNames(
        'flex',
        alignItem,
        reversed ? `flex-row-reverse justify-between` : `flex-row`,
        disabled || 'cursor-pointer'
      )}
    >
      <div className="relative mt-[4px] flex items-center justify-center">
        <QIcon_checkbox_icon width={21} height={9} className="absolute" color={handleCheckIconColor()} />

        <input
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={handleSelectedChange}
          className={classNames(
            `h-[20px] w-[20px] focus:shadow-none focus:ring-0 focus:ring-offset-0`,
            styles.shape({ shape }),
            styles.color({ disabled, checked, checkedColor }), // Pass checkedColor to styles.color
            styles.type()
          )}
        />
      </div>
      <span className={classNames(`ml-[9.5px]`, disabled && 'text-gray-200')}>{children}</span>
    </label>
  )
}

export default CheckboxItem

const styles = {
  shape: ({ shape }: { shape: CHECKBOX_SHAPE }) =>
    classNames(shape === CHECKBOX_SHAPE.SQUARE && `rounded-[4px]`, shape === CHECKBOX_SHAPE.CIRCLE && `rounded-[50%]`),
  color: ({ disabled, checked, checkedColor }: { disabled: boolean; checked: boolean; checkedColor: string }) =>
    classNames(
      !disabled && `cursor-pointer`,
      checked && disabled && `text-purple-500/50`,
      checked && !disabled && checkedColor, // Use checkedColor prop here
      !checked && disabled && `border-gray-300/50`,
      !checked && !disabled && `border-gray-300`
    ),
  type: () => 'checked:bg-none',
}
