module.exports = {
    // legacy
    teens: {
      primary: '#4c9cff',
      light: '#d5ebff',
    },
    promo: {
      accent: '#F66B0E',
      primary1: '#6D14DD',
      primary2: '#5F03BA',
      primary3: '#4D089A',
      primary4: '#440089',
    },
    transparent: 'transparent',
    current: 'currentColor',
    realBlack: '#000000',
  
    // Design System > Basic System > Color Style
    black: '#140F33',
    dim: 'rgba(0, 0, 0, 0.4)',
    white: '#FFFFFF',
    gray: {
      900: '#171B47',
      800: '#272B55',
      700: '#3E426A',
      600: '#5A5E7F',
      500: '#80839E',
      400: '#A4A7BE',
      300: '#C9CCDE',
      200: '#E4E7F4',
      100: '#EFF1F9',
      50: '#FBFBFF',
    },
    purple: {
      DEFAULT: '#503CC8',
      900: '#120B60',
      800: '#1C1374',
      700: '#2B1E90',
      600: '#3C2BAC',
      500: '#503CC8',
      400: '#7B69DE',
      300: '#9B8AEE',
      200: '#C0B3F9',
      100: '#E0D8FC',
      50: '#EEEBFA',
    },
    blue: {
      DEFAULT: '#4495FF',
      900: '#0D297A',
      800: '#153B93',
      700: '#2255B7',
      600: '#3173DB',
      500: '#4495FF',
      400: '#72B5FF',
      300: '#8EC9FF',
      200: '#D9F0FF',
      100: '#E5F2FC',
      50: '#EDF7FF',
    },
    green: {
      DEFAULT: '#2BAB63',
      900: '#085246',
      800: '#0D634C',
      700: '#157B56',
      600: '#1F935D',
      500: '#2BAB63',
      400: '#52B879',
      300: '#71C490',
      200: '#9AD4AF',
      100: '#C2E5CE',
      50: '#E6F5EB',
    },
    red: {
      DEFAULT: '#F53428',
      900: '#750725',
      800: '#8E0C25',
      700: '#B01425',
      600: '#D21D21',
      500: '#F53428',
      400: '#F9725C',
      300: '#FC997D',
      200: '#FEC3A8',
      100: '#FEE4D3',
      50: '#FFF3EF',
    },
    yellow: {
      DEFAULT: '#FFB300',
      900: '#7A4600',
      800: '#935A00',
      700: '#B77500',
      600: '#DB9300',
      500: '#FFB300',
      400: '#FFCB3F',
      300: '#FFDA66',
      200: '#FFE999',
      100: '#FFF6CC',
      50: '#FFF8E1',
    },
  }
  