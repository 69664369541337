import { default as colors } from '../colors'

export const COLORS = {
  BLACK: colors.black,
  DIM: colors.dim,
  WHITE: colors.white,

  GRAY50: colors.gray[50],
  GRAY100: colors.gray[100],
  GRAY200: colors.gray[200],
  GRAY300: colors.gray[300],
  GRAY400: colors.gray[400],
  GRAY500: colors.gray[500],
  GRAY600: colors.gray[600],
  GRAY700: colors.gray[700],
  GRAY800: colors.gray[800],
  GRAY900: colors.gray[900],

  PURPLE: colors.purple.DEFAULT,
  PURPLE50: colors.purple[50],
  PURPLE100: colors.purple[100],
  PURPLE200: colors.purple[200],
  PURPLE300: colors.purple[300],
  PURPLE400: colors.purple[400],
  PURPLE500: colors.purple[500],
  PURPLE600: colors.purple[600],
  PURPLE700: colors.purple[700],
  PURPLE800: colors.purple[800],
  PURPLE900: colors.purple[900],

  BLUE: colors.blue.DEFAULT,
  BLUE50: colors.blue[50],
  BLUE100: colors.blue[100],
  BLUE200: colors.blue[200],
  BLUE300: colors.blue[300],
  BLUE400: colors.blue[400],
  BLUE500: colors.blue[500],
  BLUE600: colors.blue[600],
  BLUE700: colors.blue[700],
  BLUE800: colors.blue[800],
  BLUE900: colors.blue[900],

  GREEN: colors.green.DEFAULT,
  GREEN50: colors.green[50],
  GREEN100: colors.green[100],
  GREEN200: colors.green[200],
  GREEN300: colors.green[300],
  GREEN400: colors.green[400],
  GREEN500: colors.green[500],
  GREEN600: colors.green[600],
  GREEN700: colors.green[700],
  GREEN800: colors.green[800],
  GREEN900: colors.green[900],

  RED: colors.red.DEFAULT,
  RED50: colors.red[50],
  RED100: colors.red[100],
  RED200: colors.red[200],
  RED300: colors.red[300],
  RED400: colors.red[400],
  RED500: colors.red[500],
  RED600: colors.red[600],
  RED700: colors.red[700],
  RED800: colors.red[800],
  RED900: colors.red[900],

  YELLOW: colors.yellow.DEFAULT,
  YELLOW50: colors.yellow[50],
  YELLOW100: colors.yellow[100],
  YELLOW200: colors.yellow[200],
  YELLOW300: colors.yellow[300],
  YELLOW400: colors.yellow[400],
  YELLOW500: colors.yellow[500],
  YELLOW600: colors.yellow[600],
  YELLOW700: colors.yellow[700],
  YELLOW800: colors.yellow[800],
  YELLOW900: colors.yellow[900],
}

export type COLOR_KEY = (typeof COLORS)[keyof typeof COLORS]

// URLS
export const CDN_URL = 'https://d38emex6h5e12i.cloudfront.net'
