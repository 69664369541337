import * as Sentry from '@sentry/react'
import cryptojs from 'crypto-js'
import fetch from 'isomorphic-unfetch'
import { useCookies } from 'react-cookie'
import Cookies from 'universal-cookie'
import { GTM_EVENT, sendGTMEvent } from '../helpers/gtmHelper'
import apis from '../modules/apis'
import { getInitialLocale } from '../modules/i18n/getInitialLocale'
import {
  COOKIE_OPT,
  COOKIE_OPT_FOR_ACCESS_TOKEN,
  EMAIL_LOGIN_PREFIX,
  FACEBOOK_APP_ID,
  SESSION_ROLE,
} from '../modules/vars'
import useTrialStore from '../store/useTrialStore'
import useUserStore from '../store/useUserStore'
import { axiosGet, axiosPost } from './axiosHelper'
import { getEnv } from './envUtil'
import { IS_DEVELOPMENT } from './envVars'
import useAppRouter, { CustomRouter } from './hooks/common/useAppRouter'
import { DEFAULT_LOCALE, LOCALE_KEY, LocaleType } from './i18n/config'
import urls from './urls'
import { COOKIE_LIST } from './vars'

export const useLogin = () => {
  const router = useAppRouter()
  const setLoginInfo = useUserStore((state) => state.login)

  return async (email, password, role, locale, isMobile = false, onFail = null) => {
    try {
      const payload: Omit<ILoginPayload, 'google_token_type'> = {
        grant_type: OAUTH_GRANT_TYPE.PASSWORD,
        username: EMAIL_LOGIN_PREFIX + email,
        password: password,
        locale: locale == LOCALE_KEY ? DEFAULT_LOCALE : locale,
        product_type: PRODUCT_TYPE.PLUS,
      }
      const response = await axiosPost(apis.auth.token(), payload, { shouldThrowError: true })

      if (response) {
        setLoginInfo({ token: response.jwt_token, currentUser: response.user })
        afterLogin(router, response, isMobile, 'email')
      }
    } catch (error) {
      const errorRes = error?.response?.data
      if (errorRes?.error) {
        alert(errorRes.error)
      }
    }
  }
}

export const moveToLoginIfNotAlready = async (currentUser, router: CustomRouter, url) => {
  if (currentUser) {
    router.push(url)
  } else {
    const cookies = new Cookies()
    cookies.set(COOKIE_LIST.LOGIN_AFTER_URL, url, COOKIE_OPT)
    router.push(`${urls.student.auth.signIn}?redirect_url=${encodeURIComponent(url)}`)
  }
}

export const afterLogin = async (router: CustomRouter, data, isMobile = false, provider = 'email') => {
  if (!data.jwt_token) {
    alert('Error')
    return
  }

  // sentry user info
  Sentry.setUser({
    email: data.user?.email,
    id: data.user?.id + '',
  })

  const isAdminLogin = data?.is_admin_login === 'true'
  const cookies = new Cookies()
  const redirectUrlQuery = router.query?.redirect_url
  const moveUrl = redirectUrlQuery || cookies.get(COOKIE_LIST.LOGIN_AFTER_URL)
  cookies.set(COOKIE_LIST.JWT_TOKEN, data.jwt_token, COOKIE_OPT_FOR_ACCESS_TOKEN)
  cookies.set(COOKIE_LIST.SESSION_ROLE, data.session_role, COOKIE_OPT)

  if (data?.is_admin_login === 'true') {
    cookies.set(COOKIE_LIST.IS_ADMIN_LOGIN, data.is_admin_login, COOKIE_OPT)
  } else {
    cookies.remove(COOKIE_LIST.IS_ADMIN_LOGIN, COOKIE_OPT)
  }

  if (data.session_role == SESSION_ROLE.STUDENT) {
    // ============ STUDENT ============
    sendGTMEvent(GTM_EVENT.SIGN_IN, {
      user_id: data?.user?.id,
    })

    console.info('Sign_in', {
      provider: provider,
      service: 'plus',
      platform: isMobile ? 'mobile_web' : 'desktop',
    })

    const trialData = await axiosGet(apis.trial.summary(), { shouldThrowError: true, initialToken: data.jwt_token })

    let url = null
    if (moveUrl) {
      cookies.remove(COOKIE_LIST.LOGIN_AFTER_URL, COOKIE_OPT)
      url = moveUrl
    } else {
      if (isMobile) {
        if (trialData?.applying_trial_lesson_possible) {
          url = urls.student.newPortal.trial.inputProfile
        } else {
          url = urls.student.landing.home
        }
      } else {
        url = urls.student.newPortal.home
      }
    }
    isAdminLogin ? router.replace(url) : router.push(url)
    return
  } else if (data.session_role == SESSION_ROLE.TUTOR) {
    // ============= TUTOR =============
    cookies.remove(COOKIE_LIST.JWT_TOKEN, COOKIE_OPT_FOR_ACCESS_TOKEN)
    cookies.remove(COOKIE_LIST.SESSION_ROLE, COOKIE_OPT)

    let url = null
    if (redirectUrlQuery) {
      url = redirectUrlQuery
    } else if (moveUrl) {
      cookies.remove(COOKIE_LIST.LOGIN_AFTER_URL, COOKIE_OPT)
      url = moveUrl
    } else {
      url = urls.tutor.auth.signIn
    }
    isAdminLogin ? router.replace(url) : router.push(url)
    return
  } else if (data.session_role == SESSION_ROLE.ADMIN) {
    window.location.href = `${urls.admin.home}`
  } else {
    isAdminLogin ? router.replace(urls.student.newPortal.home) : router.push(urls.student.newPortal.home)
  }
}

export const setUserImage = (image_url) => {
  const cookies = new Cookies()
  cookies.set('userImage', image_url, { path: '/' })
}

// O
export const signup = async (
  router: CustomRouter,
  payload,
  successCallback,
  errorCallback,
  isMobile,
  isStudentAuthentication,
  appboy = null
) => {
  try {
    const data = await axiosPost(
      isStudentAuthentication ? apis.auth.signup.studentEmail() : apis.auth.signup.tutor(),
      payload,
      { shouldThrowError: true }
    )

    if (data) {
      if (data.jwt_token) {
        const cookies = new Cookies()

        cookies.set(COOKIE_LIST.JWT_TOKEN, data.jwt_token, COOKIE_OPT_FOR_ACCESS_TOKEN)
        cookies.set(COOKIE_LIST.SESSION_ROLE, data.session_role, COOKIE_OPT)
        cookies.remove(COOKIE_LIST.REFERRAL_CODE, COOKIE_OPT)
        cookies.remove(COOKIE_LIST.FRIEND, COOKIE_OPT)
        cookies.remove(COOKIE_LIST.PHONE, COOKIE_OPT)
        cookies.remove(COOKIE_LIST.IS_ADMIN_LOGIN, COOKIE_OPT)

        successCallback && successCallback(data)
        const redirectUrlQuery = router.query?.redirect_url
        const moveUrl = redirectUrlQuery || cookies.get(COOKIE_LIST.LOGIN_AFTER_URL)
        if (moveUrl) {
          cookies.remove(COOKIE_LIST.LOGIN_AFTER_URL, COOKIE_OPT)
          router.push(moveUrl)
        } else {
          router.push(urls.student.newPortal.pricing.index)
        }
      }
    } else {
      errorCallback(data.message)
    }
  } catch (error) {
    const errorRes = error?.response?.data
    if (errorRes?.error) {
      alert(errorRes.error)
    }
  }
}

//
export const signupWithProvider = async (router: CustomRouter, payload, successCallback, errorCallback, isMobile) => {
  try {
    const data = await axiosPost(apis.auth.signup.studentSocialAccount(), payload, { shouldThrowError: true })
    if (data) {
      if (data.jwt_token) {
        const cookies = new Cookies()

        cookies.set(COOKIE_LIST.JWT_TOKEN, data.jwt_token, COOKIE_OPT_FOR_ACCESS_TOKEN)
        cookies.set(COOKIE_LIST.SESSION_ROLE, data.session_role, COOKIE_OPT)
        cookies.remove(COOKIE_LIST.EMAIL, COOKIE_OPT)
        cookies.remove(COOKIE_LIST.PROVIDER, COOKIE_OPT)
        cookies.remove(COOKIE_LIST.UID, COOKIE_OPT)

        if (payload.session_role === SESSION_ROLE.STUDENT) {
          successCallback && successCallback(data)
          const redirectUrlQuery = router.query?.redirect_url
          const moveUrl = redirectUrlQuery || cookies.get(COOKIE_LIST.LOGIN_AFTER_URL)
          if (moveUrl) {
            cookies.remove(COOKIE_LIST.LOGIN_AFTER_URL, COOKIE_OPT)
            router.push(moveUrl)
          } else {
            router.push(urls.student.newPortal.pricing.index)
          }
        } else {
          router.push(urls.tutor.landing.home)
        }
      }
    } else {
      errorCallback(data.message)
    }
  } catch (error) {
    const errorRes = error?.response?.data
    if (errorRes?.error) {
      alert(errorRes.error)
    }
  }
}

export const updateUserInfo = async (userInfo, callback) => {
  return await fetch(apis.profile.get(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getJwtToken(),
    },
    body: JSON.stringify(userInfo),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        const cookies = new Cookies()
        cookies.set(COOKIE_LIST.USER_IMAGE, data.user.image_url, { path: '/' })
        cookies.set(COOKIE_LIST.TIMEZONE, data.user.timezone, { path: '/' })
        cookies.set(COOKIE_LIST.USER_ID, data.user.id, { path: '/' })
        callback()
      } else {
        alert(data?.message)
      }
    })
    .catch((error) => {
      console.error(error)
    })
}

export const enum SOCIAL_LOGIN_PROVIDER {
  KAKAO = 'kakao',
  GOOGLE = 'google_oauth2',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
  NAVER = 'naver',
}

export const enum PRODUCT_TYPE {
  PLUS = 'plus',
  TUTOR = 'tutor',
  TEENS = 'teens',
  ADMIN = 'admin',
}

export const enum OAUTH_GRANT_TYPE {
  PASSWORD = 'password', // 일반 email/sns login 시에 사용
  DELEGATION = 'delegation', // logged_by_parent, admin fake login인 경우에 사용
}

export interface ILoginPayload {
  grant_type: OAUTH_GRANT_TYPE
  username: string
  password: string
  locale: LocaleType
  product_type: PRODUCT_TYPE
  google_token_type?: 'id_token' | 'access_token' // only for {username:'snslogin:google'}
}

export const loginWithProvider = async (
  router: CustomRouter,
  payload: ILoginPayload,
  isMobile = false,
  snsRedirectUrl = null,
  provider = 'email'
) => {
  try {
    const response = await axiosPost(apis.auth.token(), payload, { shouldThrowError: true })
    if (response) {
      processSNS(router, response, isMobile, snsRedirectUrl, provider)
    }
  } catch (error) {
    const errorRes = error?.response?.data
    if (errorRes?.error) {
      alert(errorRes.error)
    }
  }
}

export const processSNS = (router: CustomRouter, data, isMobile, snsRedirectUrl = null, provider) => {
  const locale = getInitialLocale()

  if (data) {
    if (data.is_new_user) {
      if (data.existing_phone) {
        if (locale == 'ko') {
          alert('이미 해당 번호로 가입된 계정이 있습니다. 링글팀에 문의해주세요. ')
        } else {
          alert('An account with this number already exists. Please contact us with any questions.')
        }
        if (snsRedirectUrl) router.push(snsRedirectUrl)
        return
      } else {
        const cookies = new Cookies()
        cookies.set(COOKIE_LIST.SESSION_ROLE, data.session_role, COOKIE_OPT)
        cookies.set(COOKIE_LIST.PROVIDER, data.provider, COOKIE_OPT)
        if (data.email) {
          cookies.set(COOKIE_LIST.EMAIL, data.email, COOKIE_OPT)
        }
        if (data.uid) {
          cookies.set(COOKIE_LIST.UID, data.uid, COOKIE_OPT)
        }
        if (data.name) {
          cookies.set(COOKIE_LIST.NAME, data.name, COOKIE_OPT)
        }
        if (data.phone) {
          cookies.set(COOKIE_LIST.PHONE, data.phone, COOKIE_OPT)
        }
        const url = urls.student.auth.additionalInfo
        router.push(url)
        return
      }
    } else {
      afterLogin(router, data, isMobile, provider)
    }
  }
}

export const useLogoutStudent = () => {
  const router = useAppRouter()
  const initLoginInfo = useUserStore((state) => state.logout)
  const initTrial = useTrialStore((state) => state.init)

  return () => {
    // sentry user info
    Sentry.setUser(null)
    const cookies = new Cookies()
    // 로그아웃 할때 리덕스 초기화
    initTrial()
    initLoginInfo()
    cookies.remove(COOKIE_LIST.JWT_TOKEN, COOKIE_OPT_FOR_ACCESS_TOKEN)
    cookies.remove(COOKIE_LIST.SESSION_ROLE, COOKIE_OPT)
    cookies.remove(COOKIE_LIST.EMAIL, COOKIE_OPT)
    cookies.remove(COOKIE_LIST.LOGGED_BY_PARENT, COOKIE_OPT)
    cookies.remove(COOKIE_LIST.IS_ADMIN_LOGIN, COOKIE_OPT)

    // FB delete login info
    window.sessionStorage.removeItem(`fbssls_${FACEBOOK_APP_ID}`)
    window.localStorage.removeItem(`fblst_${FACEBOOK_APP_ID}`)
    router.push(urls.student.landing.home)
  }
}

export const useLogoutTutor = () => {
  const initLoginInfo = useUserStore((state) => state.logout)
  const router = useAppRouter()

  return () => {
    // sentry user info
    Sentry.setUser(null)
    const cookies = new Cookies()
    cookies.remove(COOKIE_LIST.JWT_TOKEN, COOKIE_OPT_FOR_ACCESS_TOKEN)
    cookies.remove(COOKIE_LIST.EMAIL, COOKIE_OPT)
    cookies.remove(COOKIE_LIST.IS_ADMIN_LOGIN, COOKIE_OPT)

    initLoginInfo()
    // FB delete login info
    window.sessionStorage.removeItem(`fbssls_${FACEBOOK_APP_ID}`)
    window.localStorage.removeItem(`fblst_${FACEBOOK_APP_ID}`)

    router.push(urls.tutor.landing.home)
  }
}

export const getJwtToken = (): string => {
  const cookies = new Cookies()
  return cookies.get(COOKIE_LIST.JWT_TOKEN)
}
export const useJwtToken = (): string => {
  const [cookies] = useCookies([COOKIE_LIST.JWT_TOKEN])
  return cookies[COOKIE_LIST.JWT_TOKEN]
}

export const removeJwtToken = () => {
  const cookies = new Cookies()
  cookies.remove(COOKIE_LIST.JWT_TOKEN, COOKIE_OPT_FOR_ACCESS_TOKEN)
  IS_DEVELOPMENT &&
    cookies.remove(COOKIE_LIST.JWT_TOKEN, {
      domain: 'ringlefront.com',
      path: '/',
    })
}

export const removeSessionRole = () => {
  const cookies = new Cookies()
  cookies.remove(COOKIE_LIST.SESSION_ROLE, COOKIE_OPT)
  IS_DEVELOPMENT &&
    cookies.remove(COOKIE_LIST.SESSION_ROLE, {
      domain: 'ringlefront.com',
      path: '/',
    })
}

export const getRootUrl = () => {
  const cookies = new Cookies()
  const rootUrl = cookies.get(COOKIE_LIST.ROOT_URL)
  return rootUrl ? rootUrl : 'home'
}

export const emailRegExpServer = /^[A-Za-z0-9_.-]+@[A-Za-z0-9]+([.-][A-Za-z0-9]+)+$/
export function checkEmailValidity(asValue) {
  return emailRegExpServer.test(asValue) // 형식에 맞는 경우 true 리턴
}

export const noWhitespaceRegExp = /^\S*$/

/**
 * 휴대폰 번호 AES256으로 암호화해주는 함수
 * @param phone_number 휴대폰 번호
 * @returns 암호화된 휴대폰 번호
 */
export const encryptPhoneNum = (phone_number: string) => {
  const encodeUTF8 = (utf8_str: string) => cryptojs.enc.Utf8.parse(utf8_str)

  const key = encodeUTF8(getEnv('CONF_PHONE_AES256_KEY'))
  const iv = encodeUTF8(getEnv('CONF_PHONE_AES256_IV'))

  return cryptojs.AES.encrypt(phone_number, key, { iv }).toString()
}
