import apis, { tutor_apis } from '../apis'
import { LocaleType } from '../i18n/config'
import QueryApi from './queryApi'

//* ============================================================================================================== */
//* landing.ts
//* ============================================================================================================== */

interface IlandingCoursesQueryApiSearchParams {
  locale: LocaleType
}
export const landingCoursesQueryApi = new QueryApi<void, IlandingCoursesQueryApiSearchParams>({
  api: apis.landing.topic,
})

//* ============================================================================================================== */
//* user.ts
//* ============================================================================================================== */

export const userQueryApi = new QueryApi({ api: apis.auth.me })

//* ============================================================================================================== */
//* promotion.ts
//* ============================================================================================================== */

export const pomotionHistoryTeensQueryApi = new QueryApi({ api: tutor_apis.promotion.historyTeens })
export const promotionMissionQueryApi = new QueryApi({ api: tutor_apis.promotion.mission })

//* ============================================================================================================== */
//* geolocation.ts
//* ============================================================================================================== */

export const currentGeoQueryApi = new QueryApi({ api: apis.geolocation })

//* ============================================================================================================== */
//* application.ts
//* ============================================================================================================== */

export const getStandardizedDataQueryApi = new QueryApi({ api: tutor_apis.mockSession.statusStandardized })

export const applicationQuizQueryApi = new QueryApi({ api: tutor_apis.mockSession.applicationQuiz })

//* ============================================================================================================== */
//* classroom.ts
//* ============================================================================================================== */

interface ITutorClassroomQueryApiPathParams {
  lessonId: any
}
export const tutorClassroomQueryApi = new QueryApi<ITutorClassroomQueryApiPathParams>({
  api: tutor_apis.lessons.classroom,
})

//* ============================================================================================================== */
//* home.ts
//* ============================================================================================================== */

export const getLastMinInfoQueryApi = new QueryApi({ api: tutor_apis.lastMinute.info })

//* ============================================================================================================== */
//* lesson.ts
//* ============================================================================================================== */

interface ITutorLessonAgreementQueryApiPathParams {
  lessonId: number
}
export const tutorLessonAgreementQueryApi = new QueryApi<ITutorLessonAgreementQueryApiPathParams>({
  api: tutor_apis.lessons.lessonAgreement,
})

interface ITutorLessonStyleQueryApiPathParams {
  lessonId: number
}
export const tutorLessonStyleQueryApi = new QueryApi<ITutorLessonStyleQueryApiPathParams>({
  api: tutor_apis.lessons.lessonStyle,
})

export const upcomingLessonQueryApi = new QueryApi({ api: tutor_apis.lessons.upcomings })

//* ============================================================================================================== */
//* networkHistory.ts
//* ============================================================================================================== */

interface INetworkHistoryQueryApiPathParams {
  lessonId: any
}
export const networkHistoryQueryApi = new QueryApi<INetworkHistoryQueryApiPathParams>({
  api: tutor_apis.payment.networkHistory,
})

//* ============================================================================================================== */
//* notification.ts
//* ============================================================================================================== */

export const notificationCountQueryApi = new QueryApi({ api: tutor_apis.notifications.count })

//* ============================================================================================================== */
//* openOffice.ts
//* ============================================================================================================== */

export const officeOpenRankingQueryApi = new QueryApi({ api: tutor_apis.event.officeOpen.ranking })

//* ============================================================================================================== */
//* profile.ts
//* ============================================================================================================== */

export const tutorProfileQueryApi = new QueryApi({ api: tutor_apis.profile.basic })
export const marketingPolicyAgreement = new QueryApi({ api: tutor_apis.profile.marketingPolicyAgreement })

//* ============================================================================================================== */
//* writing.ts
//* ============================================================================================================== */

interface IWritingDetailQueryApiPathParams {
  writingId: number
}
export const writingDetailQueryApi = new QueryApi<IWritingDetailQueryApiPathParams>({ api: tutor_apis.writing.detail })

//* ============================================================================================================== */
//* incentive.ts
//* ============================================================================================================== */

export const incentiveStatusQueryApi = new QueryApi({ api: tutor_apis.payment.incentive })
