import { NextRouter } from 'next/router'
import MiddlewareCookies from '../helpers/middleware/middlewareCookie'
import { SessionRoleType } from '../model/Common/common.interface'
import { COOKIE_LIST, TEENS_SESSION_ROLE } from './vars'

export const getUserType = (userType: 'Parent' | 'Child') => {
  switch (userType) {
    case 'Parent':
      return TEENS_SESSION_ROLE.PARENT
    case 'Child':
      return TEENS_SESSION_ROLE.CHILD
  }
}

export const enum TEENS_URL {
  SIGNINPATHNAME = '/teens/auth/sign-in',
  DASHBOARD = '/teens/portal/dashboard',
  PORTAL_HOME = '/teens/portal/home',
  LANDING_HOME = '/teens/landing/home',
  SCHEDULE = '/teens/portal/lessons/schedule',
  WELCOME = '/teens/portal/welcome',
  TRIAL = '/teens/portal/trial',
  TUTOR = '/teens/portal/tutors',
  MATERIAL = '/teens/portal/materials',
  GATEWAY = '/teens/portal/dashboard/gateway',
  UNASSIGNED_LESSONS = '/teens/portal/lessons/unassigned-lessons',
  PRICING = '/teens/portal/pricing',
}

const isValidParentUrl = (pathname: string, searchParams: Record<string, string>): boolean => {
  // dashboard url이 pathname에 포함되지는 않지만 부모가 접근 가능해야하는 페이지들
  switch (true) {
    case pathname.includes('/portal/pricing'):
    case pathname.includes('/onboarding'):
    case pathname.includes('/dashboard'):
    case pathname.includes('/promotion'):
    case pathname.includes('/welcome'):
    case pathname.includes('/landing'):
    case pathname.includes(TEENS_URL.PORTAL_HOME) && 'child_id' in searchParams:
    case pathname.includes(TEENS_URL.SCHEDULE) && 'child_id' in searchParams:
    case pathname.includes(TEENS_URL.TUTOR) && 'child_id' in searchParams:
    case pathname.includes(TEENS_URL.MATERIAL) && 'child_id' in searchParams:
    case pathname.includes(TEENS_URL.UNASSIGNED_LESSONS) && 'child_id' in searchParams:
    case pathname.includes('/portal/lessons/homework'):
    case pathname.includes('/portal/lessons/preparation'):
    case pathname.includes('/portal/lessons/review'):
    case pathname.includes('/portal/trial'):
    case pathname === '/':
      return true

    default:
      return true
  }
}

const isValidChildUrl = (
  pathname: string,
  searchParams: Record<string, string>,
  cookies: MiddlewareCookies
): boolean => {
  switch (true) {
    case pathname.includes('/dashboard'):
    case pathname.includes('/pricing'):
    case pathname.includes('/onboarding'):
    case pathname.includes(TEENS_URL.SCHEDULE) && !cookies.get(COOKIE_LIST.PARENT_TOKEN) && !!searchParams?.child_id:
      return false

    default:
      return true
  }
}

/* URL이 각 session_role에 맞는 url인지 검사하는 코드들 */
interface IsProtectedUrlProps {
  pathname: string
  sessionRole: SessionRoleType
  searchParams: Record<string, string>
  cookies: MiddlewareCookies
}

export const isValidUrlAccess = ({ pathname, sessionRole, searchParams, cookies }: IsProtectedUrlProps): boolean => {
  switch (sessionRole) {
    case TEENS_SESSION_ROLE.PARENT:
      return isValidParentUrl(pathname, searchParams)
    case TEENS_SESSION_ROLE.CHILD:
      return isValidChildUrl(pathname, searchParams, cookies)
  }
}

interface RouteWithQueryProps {
  router: NextRouter
  pathname: NextRouter['pathname']
  cookiesToSet: Record<string, string>
}

export const setCookieOnMiddleware = async (props: RouteWithQueryProps) => {
  const { router, pathname, cookiesToSet } = props
  await router.push({ pathname, query: { ...router.query, ...cookiesToSet } })
}
